import { navigate } from '@reach/router';
import React, { useEffect } from 'react';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { useMutation, useQuery } from '@apollo/react-hooks';
import styled from 'styled-components';
import StyledButton from '../Styles/StyledButton';
import StyledP from '../Styles/StyledP';
import StyledA from '../Styles/StyledA';
import { CREATE_USER } from '../Graphql/mutations';
import Input, { ErrorSpan } from '../Shared/Input';
import Loading from '../Shared/Loading';
import { GET_USER } from '../Graphql/queries';
import { SectionContainer } from '../Application/Contact';
import StyledH2 from '../Styles/StyledH2';
import _get from 'lodash/get';
import { reportCustomMessage } from '../utils/sentryUtils';

const StyledDiv = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 24px;
`;

const { REACT_APP_SERVER_URL, REACT_APP_SSO_URL } = process.env;

const initialValues = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
};

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  email: Yup.string()
    .required('Email is required')
    .email('Must be a valid email'),
  password: Yup.string()
    .min(8, 'Password must be between 8 and 40 characters')
    .max(40, 'Password must be between 8 and 40 characters')
    .required('Password is required'),
});

function CreateAccount(props) {
  const user = useQuery(GET_USER);
  const params = new URLSearchParams(props.location.search);
  const newCohortId = params.get('newCohortId');
  const [mutate, { error, loading, called }] = useMutation(CREATE_USER, {
    update: (cache, { data: { createUser } }) => {
      if (!createUser.applications) createUser.applications = [];
      cache.writeQuery({
        query: GET_USER,
        data: { user: createUser },
      });
    },
  });
  if (error) {
    console.dir(error);
  }

  useEffect(() => {
    if (_get(user, 'data.user') && !called) {
      props.navigate('dashboard');
    }
  });

  return (
    <WidthContainer style={{ minHeight: 'unset' }}>
      <MotivationSection>
        <DoThis>LETS DO THIS!</DoThis>
        <StyledP>
          Your application is the first step to your new future. Get started now. Relax,{' '}
          <b>no payment</b> or <b>commitment</b> will be required during the
          application process.
        </StyledP>
      </MotivationSection>
      <SectionContainer style={{ margin: '0 auto' }}>
        <StyledH2 marginRight=".75rem">CREATE ACCOUNT</StyledH2>
        <FormContainer>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={async (formValues, { setSubmitting }) => {
              try {
                const result = await mutate({
                  variables: formValues,
                });

                navigate(
                  `/dashboard${newCohortId ? props.location.search : ''}`,
                );
                return result;
              } catch (err) {
                console.error('error occured', err.message);
                if(err.message.indexOf('user already exists') === -1) {
                  reportCustomMessage(
                    'Error encountered while getting/creating user',
                    'error',
                    {
                      progressEvent: 'true',
                      name: `${formValues.firstName} ${formValues.lastName}`,
                      email: formValues.email,
                      errorMessage: err.message,
                    },
                  );
                }
                setSubmitting(false);
              }
            }}
          >
            <Form title="create account">
              <NameContainer>
                <Input
                  name="firstName"
                  placeholder="First Name"
                  grow="1"
                  autoComplete="given-name"
                />
                <Input
                  name="lastName"
                  placeholder="Last Name"
                  grow="1"
                  autoComplete="family-name"
                />
              </NameContainer>
              <Input name="email" placeholder="Email" autoComplete="email" />
              <ErrorSpan>
                {error &&
                  (error.message || 'An error occurred').replace(
                    'GraphQL error: ',
                    '',
                  )}
              </ErrorSpan>
              <Input
                name="password"
                placeholder="Password"
                type="password"
                autoComplete="new-password"
              />
              <StyledDiv>
                {loading ? (
                  <Loading />
                ) : (
                  <StyledButton
                    variant="application"
                    type="submit"
                    fullwidth
                    disabled={loading}
                  >
                    CREATE ACCOUNT
                  </StyledButton>
                )}
              </StyledDiv>
            </Form>
          </Formik>
          <StyledP>
            Already have an account?{' '}
            <StyledA
              href={`${REACT_APP_SSO_URL}?redirect=${encodeURI(
                `${REACT_APP_SERVER_URL}/api/auth/callback${
                  props.location.search
                    ? encodeURIComponent(props.location.search)
                    : ''
                }`,
              )}`}
            >
              Sign in here
            </StyledA>
          </StyledP>
        </FormContainer>
      </SectionContainer>
    </WidthContainer>
  );
}

export default CreateAccount;

const NameContainer = styled.div`
  display: flex;
  @media (min-width: 1024px) {
    flex-wrap: wrap;
  }
`;

const WidthContainer = styled.section`
  display: flex;
  flex-direction: column;
  @media (min-width: 1024px) {
    max-width: 475px;
    margin: auto;
  }
`;

const DoThis = styled(StyledH2)`
  @media (min-width: 1024px) {
    display: none;
  }
`;

const MotivationSection = styled.section`
  margin: 0.5rem auto;
  max-width: 402px;

  @media (min-width: 1024px) {
    margin: 2.5rem 0;
    max-width: initial;
  }
`;

const FormContainer = styled.div`
  @media (min-width: 1024px) {
    width: 327px;
  }
`;
